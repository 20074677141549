<ng-container *ngIf="showSide; else otherLayout">
  <!-- <app-airplane /> -->
  <div class="mx-auto flex min-h-[var(--dynamic-screen)] flex-row">
    <ng-container [ngTemplateOutlet]="aside" />
    <ng-container [ngTemplateOutlet]="header" />
    <as-split #split direction="horizontal" class="h-[unset] flex-1 bg-bg-lighter">
      <as-split-area [size]="55" [minSize]="60000 / ((widthService.innerWidth$ | async) ?? 1)">
        <div
          appScroll
          [ngClass]="
            !splitService.getSplitView()
              ? 'mx-auto flex w-full flex-row pt-[56px] @container h-full'
              : 'mx-auto flex h-[var(--dynamic-screen)] w-full flex-row overflow-y-auto overflow-x-hidden pt-[56px] @container'
          "
        >
          <ng-container [ngTemplateOutlet]="main" />
        </div>
      </as-split-area>
      <as-split-area
        *ngIf="
          (splitService.secondScreenListener | async) !== '' &&
          ((widthService.innerWidth$ | async) ?? 0) >= 1200
        "
        class="hidden md:block"
        [size]="45"
        [minSize]="40"
      >
        <app-split-view />
      </as-split-area>
    </as-split>
  </div>
</ng-container>

<ng-template #body>
  <div class="">
    <ng-container [ngTemplateOutlet]="main" />
  </div>
</ng-template>

<ng-template #main>
  <main class="flex w-full flex-col items-center 2xl:items-start">
    <div
      class="mx-auto min-h-full w-full"
      [ngClass]="
        !bodyFull
          ? 'max-w-[90rem] px-5 pt-5 supports-[not(container-type:inline-size)]:sm:px-10 supports-[not(container-type:inline-size)]:sm:pt-10  @sm:px-10 @sm:pt-10'
          : ''
      "
    >
      <app-main-title
        class="block sm:hidden"
        titleClass="leading-[2.7rem]"
        [user]="user"
        [url]="url"
      />
      <router-outlet />
    </div>
  </main>
</ng-template>

<ng-template #header>
  <header
    class="bordered bottom-side fixed inset-x-0 top-0 z-30 flex h-16 w-full flex-row items-center justify-between bg-white px-2 lg:pr-8"
  >
    <div class="flex flex-row items-center gap-8">
      <p-button
        class="flex h-max w-max"
        styleClass="p-button-text flex p-button-rounded p-button-secondary normal-button"
        icon="pi"
        (click)="iconClick()"
      >
        <img src="assets/logo/logo_black.svg" class="w-10" alt="logo-full" />
      </p-button>
      <app-main-title
        class="hidden sm:block"
        titleClass="leading-[2.7rem]"
        [user]="user"
        [url]="url"
      />
      <!-- <app-typed values="home" /> -->
    </div>
    <div class="flex h-full flex-row items-center gap-1 md:gap-2">
      <p-button
        *ngIf="!!company?.id"
        styleClass="p-button-text p-button-rounded p-button-secondary xsmall-button"
        icon="pi"
        (click)="searchOverlayVisible = true; searchInput.focus()"
      >
        <ng-icon class="text-2xl" name="iconoirSearch" />
      </p-button>
      <div>
        <div class="badge-container">
          <p-button
            styleClass="p-button-text p-button-rounded p-button-secondary xsmall-button"
            icon="pi"
            (click)="openedNotification = true; notificationVisible = !notificationVisible"
          >
            <ng-icon *ngIf="openedNotification" class="z-50 text-2xl" name="iconoirBell" />
            <img *ngIf="!openedNotification" src="assets/bell-animation.gif" alt="bell-notif" />
          </p-button>
          <!-- <ng-icon class="text-2xl z-50" name="iconoirBell" /> -->
          <p-badge
            *ngIf="notificationUnread"
            [styleClass]="openedNotification ? 'badge-content ' : ' badge-content bounce'"
            [value]="notificationUnread < 10 ? notificationUnread.toString() : '9'"
          />
        </div>

        <ng-container [ngTemplateOutlet]="notification" />
      </div>
      <div class="mx-2 hidden h-full w-[1px] bg-border md:flex"></div>
      <div class="flex flex-row">
        <p-slideMenu
          #op
          styleClass="shadow-lg w-[13.6rem]"
          appendTo="body"
          [viewportHeight]="220"
          [backLabel]="'common.back' | translate"
          [popup]="true"
          [model]="menuItems"
          (onHide)="this.clearHighlightedOption(true)"
        >
          <ng-template pTemplate="backicon">
            <ng-icon name="iconoirNavArrowLeft" class="relative top-[3px] mr-2 inline-block" />
          </ng-template>
        </p-slideMenu>
        <p-button
          #opButton
          id="opButton"
          styleClass="flex flex-row gap-4 w-max p-button-secondary p-2 p-button-text shadow-none"
          (click)="op.toggle($event)"
        >
          <app-avatar-meta
            type="user"
            titleClass="md:block hidden text-left w-[180px] single-line"
            descriptionClass="md:block hidden text-left w-[180px] single-line"
            [avatar]="user?.avatar ?? ''"
            [title]="(this.user?.firstName ?? '') + ' ' + (this.user?.lastName ?? '')"
            [description]="this.user?.email ?? ''"
          />
        </p-button>
      </div>
    </div>
  </header>
  <div
    *ngIf="loading"
    class="loader w-full @sm:w-[calc(100%-56px)] supports-[not(container-type:inline-size)]:sm:w-[calc(100%-56px)]"
  >
    <div class="loaderBar"></div>
  </div>
</ng-template>

<ng-template #aside>
  <aside
    class="bordered right-side no-scrollbar sticky left-0 top-[56px] z-10 hidden h-[calc(var(--dynamic-screen)-56px)] w-16 flex-col items-center overflow-auto bg-white sm:flex"
    [ngClass]="sidebarText ? '2xl:w-[250px] 2xl:items-end' : ''"
  >
    <div class="w-full 2xl:w-full">
      <div class="mt-10">
        <div class="flex w-full flex-row items-center justify-center gap-2 px-4">
          <img
            appImgFallback
            class="w-12 cursor-pointer"
            alt="logo-full"
            fallbackStyle="rounded-full"
            [ngStyle]="{
              'border-radius': workspaceService.active.getValue().img ? '6px' : '100%'
            }"
            [src]="
              workspaceService.active.getValue().img
                ? (workspaceService.active.getValue().img | mediaUrl)
                : '/assets/logo/empty-company.svg'
            "
          />
          <span
            *ngIf="sidebarText"
            class="single-line hidden max-w-[calc(200px-4.5rem)] items-center text-black 2xl:inline-block"
          >
            {{ workspaceService.active.getValue().name }}
          </span>
        </div>
      </div>
      <div *ngIf="company" class="my-4 h-[1px] w-full bg-border"></div>
      <ng-container *ngTemplateOutlet="navList; context: { showText: sidebarText }" />
    </div>
    <button
      class="hidden h-16 min-h-[4rem] w-full items-center gap-4 border-none bg-white 2xl:flex vert_md:absolute vert_md:bottom-0 vert_md:left-0"
      appendTo="body"
      (click)="sidebarText = !sidebarText"
    >
      <div
        class="flex h-4 w-full items-center justify-center gap-4"
        [ngClass]="sidebarText ? '2xl:justify-start 2xl:px-10' : ''"
      >
        <ng-icon
          class="relative top-[-2px] text-2xl"
          [name]="sidebarText ? 'iconoirFastArrowLeft' : 'iconoirFastArrowRight'"
        />
        <span *ngIf="sidebarText" class="inline sm:hidden 2xl:inline">
          {{ "common.hide" | translate }}
        </span>
      </div>
    </button>
  </aside>
</ng-template>

<ng-template #notification>
  <p-overlay
    #notificationOverlay
    appendTo="body"
    mode="overlay"
    contentStyleClass="shadow-xl p-4 pb-0 bg-white rounded-lg mx-10 max-w-[25rem] w-[calc(100vw-5rem)]"
    [visible]="notificationVisible"
    (onHide)="notificationVisible = false"
  >
    <h4 class="my-0 mb-4">{{ "page.app.notification" | translate }}</h4>
    <div
      *ngIf="!notificationLoading; else loadingNotificationTemplate"
      class="mx-[-1rem] max-h-[300px] overflow-auto"
    >
      <div *ngIf="notifications.length === 0" class="mb-6">
        <app-no-data [minHeight]="false" />
      </div>
      <ng-container *ngFor="let notif of notifications; let index = index">
        <div
          class="cursor-pointer px-5 py-2 transition-colors hover:bg-bg-light"
          tabindex="0"
          [ngClass]="{
            'border border-t-0 border-x-0 border-solid border-gray-100':
              index + 1 !== notifications.length
          }"
          (click)="navigateNotification(notif); notificationOverlay.hide()"
          (keypress)="$event.preventDefault()"
        >
          <div class="flex flex-row gap-2">
            <div class="flex h-full w-5 items-center justify-center">
              <p-badge *ngIf="!notif.isSeen" styleClass="w-2 h-2 min-w-[unset] p-0" />
            </div>
            <div>
              <p class="my-0">
                {{ notif.title }}
              </p>
              <span *ngIf="notif?.description" class="font-thin text-secondary">
                {{ notif?.description ?? "" }}
              </span>
            </div>
          </div>
          <div class="flex flex-row justify-end">
            <span class="text-sm font-thin text-secondary">
              {{ notif.createdAt | formatDate }}
            </span>
          </div>
        </div>
        <!-- <p-divider *ngIf="index !== notifications.length - 1" styleClass="my-5" /> -->
      </ng-container>
    </div>
  </p-overlay>
</ng-template>

<ng-template #loadingNotificationTemplate>
  <div class="flex w-full justify-center">
    <p-progressSpinner />
  </div>
</ng-template>

<p-overlay
  #searchOverlay
  mode="modal"
  appendTo="body"
  contentStyleClass=" p-4 w-full @md:w-max h-full supports-[not(container-type:inline-size)]:md:w-max"
  [visible]="searchOverlayVisible"
  (onHide)="changeOverlay($event, 'hide')"
  (onShow)="changeOverlay($event)"
  (onAnimationDone)="changeOverlay($event)"
  (onAnimationStart)="changeOverlay($event)"
  (onBeforeShow)="changeOverlay($event)"
  (onBeforeHide)="changeOverlay($event)"
>
  <div
    class="flex h-full w-full items-center justify-center"
    tabindex="-1"
    (click)="changeOverlay($event, 'hide')"
    (keydown)="changeOverlay($event, 'hide')"
  >
    <div
      class="flex h-full max-h-[29rem] w-full max-w-2xl flex-col rounded-lg bg-white @md:min-w-[40rem] supports-[not(container-type:inline-size)]:md:min-w-[40rem] vert_md:sm:max-h-[35rem] vert_md:sm:min-h-[30rem]"
      pFocusTrap
      tabindex="-1"
      [pFocusTrapDisabled]="!searchOverlayVisible"
      (keydown)="$event.stopPropagation(); searchInput.focus()"
      (click)="$event.stopPropagation()"
    >
      <div class="flex flex-row gap-4 p-8 pb-0">
        <input
          #searchInput
          pInputText
          class="mb-4 block w-full"
          [placeholder]="'common.search' | translate"
          [(ngModel)]="searchValue"
          (ngModelChange)="search()"
          (focus)="searchOverlayVisible = true"
        />
        <p-button
          styleClass="p-button-text p-button-rounded"
          icon="pi"
          (onClick)="changeOverlay(null, 'hide')"
        >
          <ng-icon name="iconoirCancel" class="text-2xl" />
        </p-button>
      </div>
      <div class="mb-2 mr-2 overflow-auto p-8 pr-3 pt-0 vert_md:sm:pt-8" appScroll>
        <ng-container *ngIf="options.length > 0; else noData">
          <ng-container *ngFor="let option of options; let index = index">
            <p-button
              styleClass="p-button-text w-full supports-[not(container-type:inline-size)]:sm:p-4 @sm:p-4 p-1 flex flex-row gap-4 items-center"
              (onClick)="option.command(); changeOverlay(null, 'hide')"
            >
              <ng-icon class="text-2xl" [ngClass]="option.iconColor" [name]="option.icon" />
              <div class="flex flex-1 flex-col items-start">
                <h3 class="my-0 mb-2 w-full text-ellipsis text-left text-secondary-dark">
                  {{ option.label }}
                </h3>
                <span class="text-left font-thin text-secondary">
                  {{ option.description }}
                </span>
              </div>
            </p-button>
            <p-divider *ngIf="index < options.length - 1" styleClass="my-3" />
          </ng-container>
        </ng-container>
      </div>
      <ng-template #noData>
        <app-no-data [minHeight]="false" />
      </ng-template>
    </div>
  </div>
</p-overlay>

<p-sidebar
  *ngIf="showSide"
  [fullScreen]="true"
  [visible]="fullSidebar"
  (onHide)="fullSidebar = false"
>
  <ng-container *ngTemplateOutlet="navList; context: { showText: true }" />
</p-sidebar>

<ng-template #navList let-showText="showText">
  <p-listbox item styleClass="bg-transparent 2xl:pt-10 pt-24" class="w-full" [options]="routes">
    <ng-template let-route pTemplate="item">
      <button
        class="flex h-16 w-full items-center gap-4 border-none"
        appendTo="body"
        [ngClass]="{
          split:
            (route.link === '/' &&
              ((widthService.innerWidth$ | async) ?? 0) >= 1200 &&
              (splitService.secondScreenListener | async) === '/') ||
            (route.link !== '/' &&
              ((widthService.innerWidth$ | async) ?? 0) >= 1200 &&
              (splitService.secondScreenListener | async)?.startsWith(route.link)),
          active:
            (route.link === '/' && (!url.split('/')[1] || url.startsWith('/?'))) ||
            (route.link !== '/' && url.startsWith(route.link)) ||
            (route.link !== '/' && route.link.startsWith('/forum') && url.startsWith('/community')),
          'opacity-30': route.link !== '/company' && !company?.id,
          'cursor-not-allowed': route.link !== '/company' && !company?.id
        }"
        [pTooltip]="route.name"
        [escape]="false"
        (click)="networkService.isOnline() && this.navigate(route.link)"
      >
        <div
          class="flex h-4 w-full items-center justify-center gap-4"
          [ngClass]="showText ? '2xl:justify-start 2xl:px-10' : ''"
        >
          <ng-icon class="relative top-[-2px] text-2xl" [name]="route.icon" />
          <span *ngIf="showText" class="inline sm:hidden 2xl:inline">{{ route.name }}</span>
        </div>
      </button>
    </ng-template>
  </p-listbox>
</ng-template>

<ng-template #otherLayout>
  <router-outlet />
</ng-template>

<p-toast />
<p-toast key="offline" position="bottom-left" styleClass="offline-toast">
  <ng-template pTemplate="message">
    <div class="flex h-full flex-row items-center gap-4">
      <ng-icon class="text-2xl" name="iconoirAntennaOff" strokeWidth="2" />
      <span class="font-bold">
        {{ "snackbar.offline" | translate }}
      </span>
    </div>
  </ng-template>
</p-toast>
<p-toast key="online" position="bottom-left" styleClass="online-toast">
  <ng-template pTemplate="message">
    <div class="flex h-full flex-row items-center gap-4">
      <ng-icon class="text-2xl" name="iconoirAntenna" />
      <span class="font-bold">
        {{ "snackbar.online" | translate }}
      </span>
    </div>
  </ng-template>
</p-toast>

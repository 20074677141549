import { Routes } from "@angular/router";

import { AuthGuard } from "@guards/auth.guard";
import { CompanyGuard } from "@guards/company.guard";
import { VerifiedGuard } from "@guards/verified.guard";

export const routes: Routes = [
  // * PUBLIC
  {
    path: "",
    title: "Home - IONS",
    loadComponent: () => import("./pages/private/home/home.component").then(m => m.HomeComponent),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "login",
    title: "Login - IONS",
    loadComponent: () => import("./pages/public/login/login.component").then(m => m.LoginComponent),
    canActivate: [AuthGuard],
  },
  {
    path: "forgot-password",
    title: "Forgot password - IONS",
    loadComponent: () =>
      import("./pages/public/forgot-password/forgot-password.component").then(
        m => m.ForgotPasswordComponent
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "signup",
    title: "Sign up - IONS",
    loadComponent: () =>
      import("./pages/public/signup/signup.component").then(m => m.SignupComponent),
    canActivate: [AuthGuard],
  },
  {
    path: "privacy",
    title: "Privacy policy - IONS",
    loadComponent: () =>
      import("./pages/public/privacy/privacy.component").then(m => m.PrivacyComponent),
  },
  {
    path: "terms",
    title: "Terms of service - IONS",
    loadComponent: () => import("./pages/public/terms/terms.component").then(m => m.TermsComponent),
  },
  {
    path: "soon",
    title: "We will contact you soon - IONS",
    loadComponent: () => import("./pages/public/soon/soon.component").then(m => m.SoonComponent),
  },
  {
    path: "public/forum",
    title: "Forums - IONS",
    data: { public: true },
    loadComponent: () =>
      import("./pages/private/forum/forum.component").then(m => m.ForumComponent),
  },
  {
    path: "public/community/:communityId",
    title: "Forums - IONS",
    data: { public: true },
    loadComponent: () =>
      import("./pages/private/forum/single-community/single-community.component").then(
        m => m.SingleCommunityComponent
      ),
  },
  {
    path: "public/forum/:forumId",
    title: "Forums - IONS",
    data: { public: true },
    loadComponent: () =>
      import("./pages/private/forum/single-post/single-post.component").then(
        m => m.SinglePostComponent
      ),
  },

  // * PRIVATE
  {
    path: "workspace",
    title: "Workspace - IONS",
    loadComponent: () =>
      import("./pages/private/workspace/workspace.component").then(m => m.WorkspaceComponent),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "workspace/create",
    title: "Workspace creation - IONS",
    loadComponent: () =>
      import("./pages/private/workspace/create-workspace/create-workspace.component").then(
        m => m.CreateWorkspaceComponent
      ),
    canActivate: [AuthGuard, CompanyGuard, VerifiedGuard],
  },
  {
    path: "workspace/:workspaceId",
    title: "Workspace creation - IONS",
    loadComponent: () =>
      import("./pages/private/workspace/single-workspace/single-workspace.component").then(
        m => m.SingleWorkspaceComponent
      ),
    canActivate: [AuthGuard, CompanyGuard, VerifiedGuard],
  },
  {
    path: "workspace/:workspaceId/members",
    title: "Workspace members - IONS",
    loadComponent: () =>
      import("./pages/private/workspace/single-workspace/single-workspace.component").then(
        m => m.SingleWorkspaceComponent
      ),
    canActivate: [AuthGuard, CompanyGuard, VerifiedGuard],
  },
  {
    path: "settings",
    title: "Settings - IONS",
    loadComponent: () =>
      import("./pages/private/settings/settings.component").then(m => m.SettingsComponent),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "settings/create-payment",
    title: "Create payment - IONS",
    loadComponent: () =>
      import("./pages/private/payment/create-payment/create-payment.component").then(
        m => m.CreatePaymentComponent
      ),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "content",
    title: "Content - IONS",
    loadComponent: () =>
      import("./pages/private/content/content.component").then(m => m.ContentComponent),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "content/create",
    title: "Content creation - IONS",
    loadComponent: () =>
      import("./pages/private/content/create-content/create-content.component").then(
        m => m.CreateContentComponent
      ),
    canActivate: [AuthGuard, CompanyGuard, VerifiedGuard],
  },
  {
    path: "content/:contentId",
    title: "Content - IONS",
    loadComponent: () =>
      import("./pages/private/content/single-content/single-content.component").then(
        m => m.SingleContentComponent
      ),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "company",
    title: "Company - IONS",
    loadComponent: () =>
      import("./pages/private/company/company.component").then(m => m.CompanyComponent),
    canActivate: [AuthGuard],
  },
  {
    path: "company/verify",
    title: "Verify - IONS",
    loadComponent: () =>
      import("./pages/private/verify/verify.component").then(m => m.VerifyComponent),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "collaborators",
    title: "Collaborators - IONS",
    loadComponent: () =>
      import("./pages/private/collaborators/collaborators.component").then(
        m => m.CollaboratorsComponent
      ),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "roadmap",
    title: "Roadmap - IONS",
    loadComponent: () =>
      import("./pages/private/roadmap/roadmap.component").then(m => m.RoadmapComponent),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "roadmap/:roadmapId",
    title: "Single Roadmap - IONS",
    loadComponent: () =>
      import("./pages/private/roadmap/single-roadmap/single-roadmap.component").then(
        m => m.SingleRoadmapComponent
      ),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "questionnaire",
    title: "Questionnaire - IONS",
    loadComponent: () =>
      import("./pages/private/questionnaire/questionnaire.component").then(
        m => m.QuestionnaireComponent
      ),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "questionnaire/:quizId",
    title: "Single questionnaire - IONS",
    loadComponent: () =>
      import(
        "./pages/private/questionnaire/single-questionnaire/single-questionnaire.component"
      ).then(m => m.SingleQuestionnaireComponent),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "tasks",
    title: "Tasks - IONS",
    loadComponent: () =>
      import("./pages/private/tasks/tasks.component").then(m => m.TasksComponent),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "messages",
    title: "Messages - IONS",
    loadComponent: () =>
      import("./pages/private/messages/messages.component").then(m => m.MessagesComponent),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "messages/:messagesId",
    title: "Messages - IONS",
    loadComponent: () =>
      import("./pages/private/messages/messages.component").then(m => m.MessagesComponent),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "community",
    title: "Community - IONS",
    loadComponent: () =>
      import("./pages/private/forum/forum.component").then(m => m.ForumComponent),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "community/create-community",
    title: "Community Create - IONS",
    loadComponent: () =>
      import("./pages/private/forum/create-community/create-community.component").then(
        m => m.CreateCommunityComponent
      ),
    canActivate: [AuthGuard, CompanyGuard, VerifiedGuard],
  },
  {
    path: "community/:communityId",
    title: "Community - IONS",
    loadComponent: () =>
      import("./pages/private/forum/single-community/single-community.component").then(
        m => m.SingleCommunityComponent
      ),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "forum",
    title: "Forum - IONS",
    loadComponent: () =>
      import("./pages/private/forum/forum.component").then(m => m.ForumComponent),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "forum/create-forum",
    title: "Forum Create - IONS",
    loadComponent: () =>
      import("./pages/private/forum/create-forum/create-forum.component").then(
        m => m.CreateForumComponent
      ),
    canActivate: [AuthGuard, CompanyGuard, VerifiedGuard],
  },
  {
    path: "forum/:forumId",
    title: "Post - IONS",
    loadComponent: () =>
      import("./pages/private/forum/single-post/single-post.component").then(
        m => m.SinglePostComponent
      ),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "opportunities",
    title: "Opportunities - IONS",
    loadComponent: () =>
      import("./pages/private/opportunities/opportunities.component").then(
        m => m.OpportunitiesComponent
      ),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "opportunities/job/create",
    title: "Opportunity Creation - IONS",
    loadComponent: () =>
      import(
        "./pages/private/opportunities/tabs/job-opportunity/create-job-opportunity/create-job-opportunity.component"
      ).then(m => m.CreateJobOpportunityComponent),
    canActivate: [AuthGuard, CompanyGuard, VerifiedGuard],
  },
  {
    path: "opportunities/exchange/create",
    title: "Opportunity Creation - IONS",
    loadComponent: () =>
      import(
        "./pages/private/opportunities/tabs/exchange-opportunity/create-exchange-opportunity/create-exchange-opportunity.component"
      ).then(m => m.CreateExchangeOpportunityComponent),
    canActivate: [AuthGuard, CompanyGuard, VerifiedGuard],
  },
  {
    path: "opportunities/general/create",
    title: "Opportunity Creation - IONS",
    loadComponent: () =>
      import(
        "./pages/private/opportunities/tabs/general-opportunity/create-general-opportunity/create-general-opportunity.component"
      ).then(m => m.CreateGeneralOpportunityComponent),
    canActivate: [AuthGuard, CompanyGuard, VerifiedGuard],
  },
  {
    path: "opportunities/:opportunityId",
    title: "Opportunity - IONS",
    loadComponent: () =>
      import("./pages/private/opportunities/single-opportunity/single-opportunity.component").then(
        m => m.SingleOpportunityComponent
      ),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "opportunities/detail/:opportunityId",
    title: "Opportunity detail - IONS",
    loadComponent: () =>
      import(
        "./pages/private/opportunities/single-opportunity/detail/single-opportunity-detail.component"
      ).then(m => m.SingleOpportunityDetailComponent),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "opportunities/:opportunityId/:applicationId",
    title: "Applicant - IONS",
    loadComponent: () =>
      import("./pages/private/opportunities/applicant-single/applicant-single.component").then(
        m => m.ApplicantSingleComponent
      ),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "opportunities/:opportunityId/:applicationId/create",
    title: "Task creation - IONS",
    loadComponent: () =>
      import("./pages/private/task/create-task/create-task.component").then(
        m => m.CreateTaskComponent
      ),
    canActivate: [AuthGuard, CompanyGuard, VerifiedGuard],
  },
  {
    path: "tasks/common/:taskId",
    title: "Task - IONS",
    loadComponent: () =>
      import("./pages/private/task/common-task-single/common-task-single.component").then(
        m => m.CommonTaskSingleComponent
      ),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "tasks/:taskId",
    title: "Task - IONS",
    loadComponent: () =>
      import("./pages/private/task/task-single/task-single.component").then(
        m => m.TaskSingleComponent
      ),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "profile",
    title: "Profile - IONS",
    loadComponent: () =>
      import("./pages/private/profile/profile.component").then(m => m.ProfileComponent),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "user-list",
    title: "User list - IONS",
    loadComponent: () =>
      import("./pages/private/user-list/user-list.component").then(m => m.UserListComponent),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "user-list/filter",
    title: "Group Single - IONS",
    loadComponent: () =>
      import("./pages/private/user-list/filter/filter.component").then(m => m.FilterComponent),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "user-list/group/:groupId",
    title: "Group Single - IONS",
    loadComponent: () =>
      import("./pages/private/user-list/group-single/group-single.component").then(
        m => m.GroupSingleComponent
      ),
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: "user-list/user/:userId",
    title: "User Single - IONS",
    loadComponent: () =>
      import("./pages/private/user-list/user-single/user-single.component").then(
        m => m.UserSingleComponent
      ),
    canActivate: [AuthGuard, CompanyGuard],
  },
  // {
  //   path: "user-list/preview/:id",
  //   title: "Preview - IONS",
  //   loadComponent: () =>
  //     import("./pages/private/user-list/sheet-preview/sheet-preview.component").then(
  //       m => m.SheetPreviewComponent
  //     ),
  //   canActivate: [AuthGuard, CompanyGuard],
  // },
  {
    path: "**",
    title: "404 - IONS",
    loadComponent: () =>
      import("./pages/public/not-found/not-found.component").then(m => m.NotFoundComponent),
    canActivate: [AuthGuard],
  },
];
